import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const meaculpa = () => (
  <Layout>
    <SEO title="Bananes Citronnées - Mea culpa" />
    <h3 className='underline-title'>Mea culpa</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Lorsque Dieu inonda la terre<br />
        Par son déluge préhistorique<br />
        Je voltigeais encore dans le cosmos<br />
        J'aurais voulu y verser mon seau d'eau<br />
        Pour noyer davantage la planète-rebelle<br />
        C'est ma faute d'avoir papillonné en cosmos<br />
      </p>
      <p>
        Lorsqu'Adam mangea sa funeste pomme<br />
        Je plantais du palmier au jardin tropical<br />
        J'aurais voulu piller entièrement ce pommier<br />
        Pour dévaster définitivement le jardin édénique<br />
        C'est ma faute de m'être attardé au jardin du Tropique<br />
      </p>
      <p>
        Lorsque Caïn assomma son frère Abel<br />
        D'un coup de gourdin criminel<br />
        Je chassais encore en Mésopotamie<br />
        J'aurais voulu l'assener d'un second coup<br />
        Pour qu'il crève éternellement<br />
        C'est ma faute de n'avoir pas été présent au lieu d'action<br />
      </p>
      <p>
        Lorsqu'un séisme ravagea l'Asie<br />
        Je m'entrainais à la rhétorique d'Aristote<br />
        J'aurais voulu secouer très fort la terre<br />
        Pour effondrer les gratte-ciels new-yorkais<br />
        C'est ma faute d'avoir folâtré avec le Grec<br />
      </p>
      <p>
        Lorsqu'ils ont crucifié leur Jésus de Nazareth<br />
        Je cueillais des champignons en forêts vierges<br />
        J'aurais voulu lui enfoncer un cinquième clou<br />
        Pour qu'il meure deux fois<br />
        C'est ma faute d'avoir habité si loin de Golgotha<br />
      </p>
      <p>
        Lorsque Führer distribua des coups aux Jacobites<br />
        Je silonnais les déserts sahariens<br />
        J'aurais voulu l'encourager par mes cris de guerre<br />
        Pour qu'il les corrige sévérement<br />
        C'est ma faute d'avoir vadrouillé en Afrique<br />
      </p>
      <p>
        Lorsque dipanda sonna opulence<br />
        Je faisais le petit saint<br />
        J'aurais voulu amasser avec eux<br />
        Pour avoir uen place au soleil<br />
        C'est ma faute d'avoir croisé les bras<br />
      </p>
      <p>
        Lorsqu'ils pillaient les écorces du quinquina<br />
        Je faisais le Don Quichotte<br />
        Ce petit général à cheval esseulé<br />
        Qui se faisait imbécilement d'illusion<br />
        C'est ma très grande faute<br />
        D'avoir lutté contre les moulins à vent<br />
      </p>
    </div>
    <hr/>
    <p className="date-published">Bukavu, le 5 août 1976</p>
    <br />
    <br />
    <Link to="/bananes-citronnees-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default meaculpa
